'use client';

import { UIContextProvider } from '@mnd-frontend/ui';
import { Resource } from 'i18next';
import { throttle } from 'lodash-es';
import { ReadonlyURLSearchParams, usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useRef } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { LanguageLinksProvider } from '../contexts/LanguageLinksContext';
import { track } from '../lib/tracker';
import { initI18next } from './i18n';

const parseParamsToJson = (searchParams: ReadonlyURLSearchParams | URLSearchParams) => {
  const params: { [anyProp: string]: string } = {};

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};

function useGetAllSearchParams() {
  return parseParamsToJson(useSearchParams());
}

const useLocation = () => {
  const pathname = usePathname();
  const allSearchParams = useGetAllSearchParams();

  return {
    hash: window.location.hash,
    pathname,
    search: allSearchParams,
  } as any;
};

export default function Providers({
  children,
  locale,
  i18nConfig,
}: {
  children: React.ReactNode;
  locale: string;
  i18nConfig: Resource;
}) {
  const pathname = usePathname();
  const router = useRouter();
  const lastPathnameRef = useRef<string | undefined>();

  const initializedGaThroughNativePageRef = useRef(false);
  useEffect(() => {
    if (initializedGaThroughNativePageRef.current) return;

    track({
      event: 'Native Page',
      traits: {
        applicationLanguage: locale,
      },
    });
    initializedGaThroughNativePageRef.current = true;
  }, [locale]);

  useEffect(() => {
    if (lastPathnameRef.current === pathname) return;
    let pageReferrer = document.referrer;
    if (lastPathnameRef.current) {
      const url = new URL(window.location.href);
      url.pathname = lastPathnameRef.current;
      pageReferrer = url.href;
    }
    track({
      event: 'page_view',
      traits: {
        event_info: {
          page_referrer: pageReferrer,
        },
      },
    });
    lastPathnameRef.current = pathname;
  }, [pathname]);

  useEffect(() => {
    let previousScrollPercentage = 0;
    const scrollTriggerPercentages = [90, 75, 50, 25];
    const scrollHandler = () => {
      const scrollTop = window.scrollY;
      const docHeight = document.documentElement.scrollHeight;
      const winHeight = window.innerHeight;

      const scrollPercentage = (scrollTop / (docHeight - winHeight)) * 100;
      const triggeredPercentage = scrollTriggerPercentages.find(
        trigger => scrollPercentage > trigger && previousScrollPercentage <= trigger,
      );
      if (triggeredPercentage) {
        track({
          event: 'scroll',
          traits: {
            event_info: {
              scroll_depth: triggeredPercentage,
              viewport_height: winHeight,
              document_height: docHeight,
            },
          },
        });
      }
      previousScrollPercentage = scrollPercentage;
    };
    const throttledHandler = throttle(scrollHandler, 200);
    window.addEventListener('scroll', throttledHandler);
    return () => {
      window.removeEventListener('scroll', throttledHandler);
    };
  }, [pathname]);

  const i18nInstance = initI18next(locale, i18nConfig)[0];

  return (
    <I18nextProvider i18n={i18nInstance}>
      <UIContextProvider
        value={{
          useLocation,
          useNavigate: () => location => {
            const target =
              typeof location === 'string' ? location : `${pathname}${location.hash ?? ''}`;
            router.push(target);
          },
          useTranslation,
        }}
      >
        <LanguageLinksProvider>{children}</LanguageLinksProvider>
      </UIContextProvider>
    </I18nextProvider>
  );
}
