export const routes = {
  tryMonitorReportsForFree: (lang: string) => {
    switch (lang) {
      case 'en':
        return 'https://www.mynewsdesk.com/en/monitor-reports-trial/';
      case 'se-sv':
        return 'https://www.mynewsdesk.com/se-sv/monitor-reports-trial/';
      case 'de-de':
        return 'https://www.mynewsdesk.com/de-de/monitor-reports-trial/';
      case 'dk-da':
        return 'https://www.mynewsdesk.com/dk-da/monitor-reports-trial/';
      case 'no-no':
        return 'https://www.mynewsdesk.com/no-no/monitor-reports-trial/';
      default:
        return '';
    }
  },
  termsConditions: (lang: string) => {
    switch (lang) {
      case 'en':
        return 'https://www.mynewsdesk.com/about/terms-and-conditions';
      case 'se-sv':
        return 'https://www.mynewsdesk.com/se/about/terms-and-conditions';
      case 'de-de':
        return 'https://www.mynewsdesk.com/de/about/terms-and-conditions';
      case 'dk-da':
        return 'https://www.mynewsdesk.com/dk/about/terms-and-conditions';
      case 'no-no':
        return 'https://www.mynewsdesk.com/no/about/terms-and-conditions';
      default:
        return 'https://www.mynewsdesk.com/about/terms-and-conditions';
    }
  },
  termsUse: (lang: string) => {
    switch (lang) {
      case 'en':
        return 'https://www.mynewsdesk.com/about/terms-and-conditions/terms_and_conditions';
      case 'se-sv':
        return 'https://www.mynewsdesk.com/se/about/terms-and-conditions/terms_and_conditions';
      case 'de-de':
        return 'https://www.mynewsdesk.com/about/terms-and-conditions/terms_and_conditions';
      case 'dk-da':
        return 'https://www.mynewsdesk.com/about/terms-and-conditions/terms_and_conditions';
      case 'no-no':
        return 'https://www.mynewsdesk.com/no/about/terms-and-conditions/terms_and_conditions';
      default:
        return 'https://www.mynewsdesk.com/about/terms-and-conditions/terms_and_conditions';
    }
  },
  privacyPolicy: (lang: string) => {
    switch (lang) {
      case 'en':
        return 'https://www.mynewsdesk.com/about/terms-and-conditions/privacy_policy';
      case 'se-sv':
        return 'https://www.mynewsdesk.com/se/about/terms-and-conditions/privacy_policy';
      case 'de-de':
        return 'https://www.mynewsdesk.com/about/terms-and-conditions/privacy_policy';
      case 'dk-da':
        return 'https://www.mynewsdesk.com/about/terms-and-conditions/privacy_policy';
      case 'no-no':
        return 'https://www.mynewsdesk.com/no/about/terms-and-conditions/privacy_policy';
      default:
        return 'https://www.mynewsdesk.com/about/terms-and-conditions/privacy_policy';
    }
  },
  consentPolicy: (lang: string) => {
    switch (lang) {
      case 'en':
        return 'https://www.mynewsdesk.com/about/terms-and-conditions/privacy_policy';
      case 'se-sv':
        return 'https://www.mynewsdesk.com/se/about/terms-and-conditions/privacy_policy';
      case 'de-de':
        return 'https://www.mynewsdesk.com/de/about/terms-and-conditions/privacy_policy';
      case 'dk-da':
        return 'https://www.mynewsdesk.com/dk/about/terms-and-conditions/privacy_policy';
      case 'no-no':
        return 'https://www.mynewsdesk.com/no/about/terms-and-conditions/privacy_policy';
      default:
        return 'https://www.mynewsdesk.com/se/about/terms-and-conditions/privacy_policy';
    }
  },
};
