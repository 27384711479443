'use client';
import { createContext, ReactNode, useContext, useLayoutEffect, useState } from 'react';
import { PageFragment } from '../wp-generated/types';

interface LanguageLinksContext {
  'se-sv': string;
  'de-de': string;
  'dk-da': string;
  'no-no': string;
  en: string;
}

const DEFAULT_LINKS = {
  'se-sv': '',
  'de-de': '',
  'dk-da': '',
  'no-no': '',
  en: '',
};

export const LanguageLinksContext = createContext<LanguageLinksContext>(DEFAULT_LINKS);
const LanguageLinksSetterContext = createContext<(arg: LanguageLinksContext) => void>(() => {
  throw new Error('missing context provider');
});

export const useSetLanguageLinks = (links: PageFragment['translations']) => {
  const setter = useContext(LanguageLinksSetterContext);
  useLayoutEffect(() => {
    const formattedLinks: Record<string, string> = {};
    links?.forEach(link => {
      if (!link?.language?.slug || !link?.slug) return;
      const slug = [
        link.parent?.node && 'parent' in link.parent.node
          ? link.parent?.node.parent?.node.slug
          : null,
        link.parent?.node.slug,
        link.slug,
      ]
        .filter(Boolean)
        .join('/');
      formattedLinks[link.language.slug] = slug;
    });
    setter({
      'se-sv': formattedLinks['se-sv'] || '',
      'de-de': formattedLinks['de-de'] || '',
      'dk-da': formattedLinks['dk-da'] || '',
      'no-no': formattedLinks['no-no'] || '',
      en: formattedLinks['en'] || '',
    });
  }, [links, setter]);
};

export const LanguageLinksProvider = ({ children }: { children: ReactNode }) => {
  const [languageLinks, setLanguageLinks] = useState<LanguageLinksContext>(DEFAULT_LINKS);

  return (
    <LanguageLinksContext.Provider value={languageLinks}>
      <LanguageLinksSetterContext.Provider value={setLanguageLinks}>
        {children}
      </LanguageLinksSetterContext.Provider>
    </LanguageLinksContext.Provider>
  );
};
