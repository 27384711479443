export const locales = [
  { locale: 'dk-da', label: 'Dansk' },
  { locale: 'de-de', label: 'Deutsch' },
  { locale: 'en', label: 'English' },
  { locale: 'no-no', label: 'Norsk' },
  { locale: 'se-sv', label: 'Svenska' },
] as const;

export const mapLangToLocale = (lang?: string): string => {
  switch (lang) {
    case 'en':
      return 'en';
    case 'se-sv':
      return 'sv';
    case 'de-de':
      return 'de';
    case 'dk-da':
      return 'da';
    case 'no-no':
      return 'no';
    default:
      return 'en';
  }
};

export const mapSiteLocaleToLangCountryCode = (locale?: string): string => {
  switch (locale) {
    case 'en':
      return 'en_US';
    case 'se-sv':
      return 'sv_SE';
    case 'de-de':
      return 'de_DE';
    case 'dk-da':
      return 'da_DK';
    case 'no-no':
      return 'nb_NO';
    default:
      return 'en_US';
  }
};

export type Locales = 'se-sv' | 'de-de' | 'dk-da' | 'no-no' | 'en';
