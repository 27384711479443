import React from 'react';
import styled from 'styled-components';
import { ButtonVariants, getButtonStyling } from '../Button/styling';

const Link = styled.a<{ $variant: ButtonVariants; $fullWidth?: boolean }>`
  ${({ $variant, $fullWidth }) => getButtonStyling({ $variant, $fullWidth })}
`;

type Props = {
  children: React.ReactNode;
  href: string;
  variant: ButtonVariants;
  fullWidth?: boolean;
  className?: string;
  target?: string;
  id?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const LinkButton = ({
  id,
  children,
  href,
  variant,
  fullWidth,
  className,
  target,
  onClick,
}: Props) => (
  <Link
    id={id}
    href={href}
    $variant={variant}
    $fullWidth={fullWidth}
    className={className}
    target={target}
    onClick={onClick}
  >
    {children}
  </Link>
);

export default LinkButton;
